import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  private _messages: { [key: string]: { [key: string]: string; } } = {};

  get messages(): { [key: string]: { [key: string]: string; } } {
    return this._messages;
  }

  set messages(messages: { [key: string]: { [key: string]: string; } }) {
    this._messages = messages;
  }

  getFormattedMessage(msg: string, values?: any[]): string {
    if (values && values.length > 0) {
      for (let i = 0, len = values.length; i < len; i++) {
        msg = msg.replace('{' + i + '}', values[i]);
      }
    }
    return msg;
  }

  extend(obj1: any, obj2: any): any {
    let result: any = JSON.parse(JSON.stringify(obj1));
    const keys = Object.keys(obj2);
    for(let key of keys){
      const val = obj2[key];
      if (typeof val === 'string') {
        result[key] = val;
      } else {
        result[key] = this.extend(result[key] || {}, val);
      }
    }
    return result;
  }

  getErrorMessage(status, msg) {
    let m;
    switch (status) {
      case 400:
        m = msg.badRequest;
        break;
      case 401:
        m = msg.unauthorized;
        break;
      case 403:
        m = msg.forbidden;
        break;
      case 404:
        m = msg.notFound;
        break;
      case 500:
        m = msg.internalServerError;
        break;
      case 0:
        m = msg.requestAborted;
        break;
      default:
        m = msg.unknownError;
    }
    return m;
  }

}
