import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class XPlatformLoginService {

  constructor(private http: HttpClient) { }

  xValidateSession(requestedPage: string): Observable<any> {
    const url = '/loginwebapp/rest/sso/validateSession';
    let params = new HttpParams();
    params = params.set('requestedPage', requestedPage);
    params = params.set('xPlatform', 'true');
    return this.http.get(url, { params: params });
  }

  xLoginUrl(requestedPage: string): Observable<any> {
    const url = '/loginwebapp/rest/sso/xPlatform/loginUrl';
    let params = new HttpParams();
    params = params.set('requestedPage', requestedPage);
    return this.http.get(url, { params: params });
  }


}
