export const SAML_CODE: { [key: string]: string } = {
    '100': 'UNDEFINED_SAML_ISSUE',
    '101': 'SUGGESTED_APP_MODULE_DOES_NOT_EXIST',
    '201': 'ODP_USER_MAPPED_BY_EXTERNAL_ID_DOES_NOT_EXIST',
    '202': 'EXTERNAL_USER_ID_NOT_FOUND_IN_DB',
    '203': 'ODP_USER_MAPPING_IS_NOT_APPROVED',
    '204': 'ODP_USER_MUST_BE_ACTIVATED',
    '205': 'NO_REDIRECT_URI_IN_SAML_REQUEST',
    '301': 'CERTIFICATE_ISSUE_NOT_VALID_SIGNATURE',
    '302': 'CERTIFICATE_ISSUE_NOT_FOUND',
    '303': 'CERTIFICATE_ISSUE_CONDITIONS_OUT_OF_INTERVAL',
    '304': 'CERTIFICATE_ISSUE_INVALID_KEY_SPEC',
    '305': 'CERTIFICATE_ISSUE_NO_SUCH_ALGORITHM',
    '306': 'CERTIFICATE_ISSUE_MISSING_PARAMETER',
    '307': 'EXTERNAL_COMPANY_ID_NOT_FOUND_IN_DB',
    '000': 'CERTIFICATE_ISSUE_UNDEFINED',
    '401': 'CONFIGURATION_SSO_NOT_ACTIVATED_FOR_APPLICATION_TYPE',
    '500': 'INTERNAL_SERVER_ERROR'
}
