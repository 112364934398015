export const environment = {
  production: true,
  defaultLanguage: 'en',
  supportedLanguages: ['da', 'en', 'fi', 'nb', 'nl', 'no', 'sv'],
  excludedUrlsFromRetryStrategy: ['/assets/i18n/'],
  api: {
    localhost: 'http://localhost:4200',
    int: 'https://api.signin.aws.int.test.visma.net',
    sit: 'https://api.signin.sit.test.visma.net',
    stag: 'https://api.signin.stag.visma.net',
    prod: 'https://api.signin.visma.net'
  },
  home: {
    localhost: 'http://localhost:4200',
    int: 'https://home.aws.int.test.visma.net',
    sit: 'https://home.sit.test.visma.net',
    stag: 'https://home.stag.visma.com',
    prod: 'https://home.visma.com'
  }
};
