import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LoginPageService {

  constructor(private http: HttpClient) { }

  private handleRequest(url: string, requestedPage?: string) {
    if (requestedPage && requestedPage.length > 0) {
      let params = new HttpParams();
      params = params.set('requestedPage', requestedPage);
      return this.http.get(url, { params: params });
    } else {
      return this.http.get(url);
    }
  }

  validateSession(requestedPage?: string): Observable<any> {
    const url = '/loginwebapp/rest/sso/validateSession';
    return this.handleRequest(url, requestedPage);
  }

  loginUrl(requestedPage?: string): Observable<any> {
    const url = '/loginwebapp/rest/sso/loginUrl';
    return this.handleRequest(url, requestedPage);
  }

  logout(): Observable<any> {
    const url = '/loginwebapp/rest/sso/logout';
    return this.http.get(url);
  }

}
