import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WindowRef } from '../../core/domain/window-ref';
import { LoginPageService } from './login-page.service';
import { MSG_CODE } from './msg-code';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html'
})
export class LoginPageComponent implements OnInit {

  constructor(private service: LoginPageService, private router: Router,
    private route: ActivatedRoute, private windowRef: WindowRef) { }

  ngOnInit(): void {

    this.route.queryParams.subscribe(params => {
      const msgCode = params['msgCode'];
      const requestedPage = params['requestedPage'];

      if (msgCode === MSG_CODE.error) {
        this.router.navigate(['/error']);
      } else if (msgCode === MSG_CODE.notLoggedIn) {
        this.service.loginUrl(requestedPage).subscribe({
          next: response => {
            this.windowRef.setLocationHref(response.url);
          },
          error: error => {
            this.router.navigate(['/error'], {
              queryParams: {
                method: 'loginUrl', status: error.status,
                correlationId: error.headers.get('x-correlation-id'), timestamp: this.windowRef.getTime()
              }
            });
          }
        });
      } else if (msgCode === MSG_CODE.logout || msgCode === MSG_CODE.connectLogout) {
        this.service.logout().subscribe({
          next: response => {
            this.windowRef.setLocationHref(response.logoutUrl);
          },
          error: error => {
            this.router.navigate(['/error'], {
              queryParams: {
                method: 'logout', status: error.status,
                correlationId: error.headers.get('x-correlation-id'), timestamp: this.windowRef.getTime()
              }
            });
          }
        });
      } else {
        this.service.validateSession(requestedPage).subscribe({
          next: response => {
            this.windowRef.setLocationHref(response.url);
          },
          error: error => {
            if (error.status === 401) {
              this.service.loginUrl(requestedPage).subscribe({
                next: response => {
                  this.windowRef.setLocationHref(response.url);
                },
                error: err => {
                  this.router.navigate(['/error'], {
                    queryParams: {
                      method: 'loginUrl', status: err.status,
                      correlationId: err.headers.get('x-correlation-id'), timestamp: this.windowRef.getTime()
                    }
                  });
                }
              });
            } else {
              this.router.navigate(['/error'], {
                queryParams: {
                  method: 'validateSession', status: error.status,
                  correlationId: error.headers.get('x-correlation-id'), timestamp: this.windowRef.getTime()
                }
              });
            }
          }
        });
      }
    });
  }

}
