import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { WindowRef } from './core/domain/window-ref';
import { ProfileService } from './core/translation/profile.service';
import { TranslationService } from './core/translation/translation.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

  hasProfileSet = false;

  constructor(private windowRef: WindowRef, private translation: TranslationService, private pfs: ProfileService,
    private router: Router) { }

  ngOnInit(): void {
    const language = this.windowRef.getBrowserLanguage();
    const deflang = environment.defaultLanguage;
    const shouldRedirect = this.windowRef.shouldRedirect(this.windowRef.getPathname());
    const search = this.windowRef.getSearch();
    const url = `/loginwebapp/loginPage.xhtml${search}`;
    
    this.translation.getTranslation(deflang).subscribe(response => {
      if (environment.supportedLanguages.indexOf(language) === -1) {
        this.pfs.messages = response;
        this.hasProfileSet = true;
        if (shouldRedirect) {
          this.router.navigateByUrl(url);
        }
      } else {
        this.translation.getTranslation(language).subscribe(result => {
          this.pfs.messages = this.pfs.extend(response, result);
          this.hasProfileSet = true;
          if (shouldRedirect) {
            this.router.navigateByUrl(url);
          }
        });
      }
    });
  }
}
