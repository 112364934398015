import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { Observable } from "rxjs";
import { DomainService } from '../domain/domain.service';
import { WindowRef } from '../domain/window-ref';


@Injectable()
export class BaseUrlInterceptor implements HttpInterceptor {
    constructor(private domain: DomainService, private windowRef: WindowRef) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (this.domain.isUrlExcludedFromRetryStrategy(req.url)) {
            return next.handle(req);
        } else {
            const hostname = this.windowRef.getHostname();
            const baseUrl = this.domain.getBaseUrl(hostname);
            const clone = req.clone({
                url: `${baseUrl}${req.url}`,
                withCredentials: true
            });

            return next.handle(clone);
        }
    }
}
