import { Component, OnInit } from '@angular/core';
import { DomainService } from 'src/app/core/domain/domain.service';
import { WindowRef } from 'src/app/core/domain/window-ref';
import { ProfileService } from 'src/app/core/translation/profile.service';

@Component({
  selector: 'app-access-denied',
  templateUrl: './access-denied.component.html'
})
export class AccessDeniedComponent implements OnInit {

  private translationIndex = 'accessDenied';
  private commonIndex = 'common';
  message: any;
  homeUrl: string;

  constructor(private pfs: ProfileService, private windowRef: WindowRef, private domain: DomainService) { }

  ngOnInit(): void {
    this.message = Object.assign(this.pfs.messages[this.commonIndex], this.pfs.messages[this.translationIndex]);
    this.homeUrl = this.domain.getHomeUrl(this.windowRef.getHostname());
  }

}
