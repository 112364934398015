import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HOSTNAME } from '../const/hostname';


@Injectable({
  providedIn: 'root'
})
export class DomainService {

  constructor() { }

  getBaseUrl(hostname: string): string {
    if (hostname === HOSTNAME.sit) {
      return environment.api.sit;
    } else if (hostname === HOSTNAME.int) {
      return environment.api.int;
    } else if (hostname === HOSTNAME.stag) {
      return environment.api.stag;
    } else if (hostname === HOSTNAME.prod) {
      return environment.api.prod;
    } else {
      return environment.api.localhost;
    }
  }

  getHomeUrl(hostname: string): string {
    if (hostname === HOSTNAME.sit) {
      return environment.home.sit;
    } else if (hostname === HOSTNAME.int) {
      return environment.home.int;
    } else if (hostname === HOSTNAME.stag) {
      return environment.home.stag;
    } else if (hostname === HOSTNAME.prod) {
      return environment.home.prod;
    } else {
      return environment.home.localhost;
    }
  }

  private indexOf(str: string, arr: string[]): boolean {
    let is = false;
    for (let i = 0; i < arr.length; i++) {
      if (str.indexOf(arr[i]) > -1) {
        is = true;
        break;
      }
    }
    return is;
  }

  isUrlExcludedFromRetryStrategy(url: string): boolean {
    const arr: string[] = environment.excludedUrlsFromRetryStrategy;
    return this.indexOf(url, arr);
  }

}
