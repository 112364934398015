import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccessDeniedComponent } from './access-denied/access-denied.component';
import { ErrorComponent } from './error/error.component';
import { LoginPageComponent } from './login-page/login-page.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { SamlErrorComponent } from './saml-error/saml-error.component';
import { SsoUserIsNotValidErrorComponent } from './sso-user-is-not-valid-error/sso-user-is-not-valid-error.component';
import { SystemUnavailableErrorComponent } from './system-unavailable-error/system-unavailable-error.component';
import { XPlatformErrorComponent } from './x-platform-error/x-platform-error.component';
import { XPlatformLoginComponent } from './x-platform-login/x-platform-login.component';

const routes: Routes = [
  {
    path: 'loginPage.xhtml',
    component: LoginPageComponent
  },
  {
    path: 'vismaConnectCallback.xhtml',
    component: LoginPageComponent
  },
  {
    path: 'xPlatformLogin.xhtml',
    component: XPlatformLoginComponent
  },
  {
    path: 'xPlatformError.html',
    component: XPlatformErrorComponent
  },
  {
    path: 'AccessDenied.html',
    component: AccessDeniedComponent
  },
  {
    path: 'SystemUnavailableError.html',
    component: SystemUnavailableErrorComponent
  },
  {
    path: 'SsoUserIsNotValidError.html',
    component: SsoUserIsNotValidErrorComponent
  },
  {
    path: 'SamlError.html',
    component: SamlErrorComponent
  },
  {
    path: 'private',
    loadChildren: () => import('./private/private.module').then(m => m.PrivateModule)
  },
  {
    path: 'error',
    component: ErrorComponent
  },
  { path: '**', component: PageNotFoundComponent }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LoginwebappRoutingModule { }
