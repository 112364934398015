import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DomainService } from './domain/domain.service';
import { WindowRef } from './domain/window-ref';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    DomainService,
    WindowRef
  ]
})
export class CoreModule { }
