import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-x-platform-error',
  templateUrl: './x-platform-error.component.html'
})
export class XPlatformErrorComponent implements AfterViewInit {

  xPlatformRedirectUrl: string;
  errorMessage: string;
  @ViewChild('frm') frm: ElementRef;

  constructor(private route: ActivatedRoute, private cd: ChangeDetectorRef) { }

  ngAfterViewInit(): void {
    this.route.queryParams.subscribe(params => {
      this.xPlatformRedirectUrl = params['redirectUrl'];
      this.errorMessage = params['errorMessage'];
      this.cd.detectChanges();
      this.frm.nativeElement.submit();
    });
  }

}
