<main class="container">
    <div class="row error-page align-items-center">
        <div class="error-page-content col-12 col-md-6">
            <h1>{{message.header}}</h1>
            <p>{{message.infoUnexpectedError}}</p>
            <div *ngIf="status">
                <p>{{message.method}} {{method}}: {{status}} {{info}}</p>
                <p>{{message.correlationId}}: {{correlationId}}</p>
                <p>{{message.date}}: {{date}}</p>
            </div>
            <a class="btn btn-primary" [href]="homeUrl">{{message.buttonGoToHomePage}}</a>
        </div>
        <div class="error-page-image col-12 col-md-6">
            <img class="error-page-image" alt="Error" src="assets/images/server_error.svg">
        </div>
    </div>
</main>
