<main class="container">
    <div class="row error-page align-items-center">
        <div class="error-page-content col-12 col-md-6">
            <h1>{{message.header}}</h1>
            <p>{{message.info}}</p>
            <a class="btn btn-primary" [href]="homeUrl">{{message.buttonGoToHomePage}}</a>
        </div>
        <div class="error-page-image col-12 col-md-6">
            <img class="error-page-image" alt="Error" src="assets/images/error-access-denied.svg">
        </div>
    </div>
</main>
