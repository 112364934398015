import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoginwebappRoutingModule } from './loginwebapp-routing.module';
import { LoginwebappComponent } from './loginwebapp.component';
import { LoginPageComponent } from './login-page/login-page.component';
import { XPlatformLoginComponent } from './x-platform-login/x-platform-login.component';
import { ErrorComponent } from './error/error.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AccessDeniedComponent } from './access-denied/access-denied.component';
import { SystemUnavailableErrorComponent } from './system-unavailable-error/system-unavailable-error.component';
import { SsoUserIsNotValidErrorComponent } from './sso-user-is-not-valid-error/sso-user-is-not-valid-error.component';
import { SamlErrorComponent } from './saml-error/saml-error.component';
import { XPlatformErrorComponent } from './x-platform-error/x-platform-error.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

@NgModule({
  declarations: [
    LoginwebappComponent,
    LoginPageComponent,
    XPlatformLoginComponent,
    ErrorComponent,
    AccessDeniedComponent,
    SystemUnavailableErrorComponent,
    SsoUserIsNotValidErrorComponent,
    SamlErrorComponent,
    XPlatformErrorComponent,
    PageNotFoundComponent
  ],
  imports: [
    CommonModule,
    LoginwebappRoutingModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class LoginwebappModule { }
