import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WindowRef } from '../../core/domain/window-ref';
import { XPlatformLoginService } from './x-platform-login.service';

@Component({
  selector: 'app-x-platform-login',
  templateUrl: './x-platform-login.component.html'
})
export class XPlatformLoginComponent implements OnInit {

  constructor(private service: XPlatformLoginService, private router: Router,
    private route: ActivatedRoute, private windowRef: WindowRef) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      const requestedPage = params['requestedPage'];
      this.service.xValidateSession(requestedPage).subscribe({
        next: response => {
          this.windowRef.setLocationHref(response.url);
        },
        error: error => {
          if (error.status === 401) {
            this.service.xLoginUrl(requestedPage).subscribe({
              next: response => {
                this.windowRef.setLocationHref(response.url);
              },
              error: err => {
                this.router.navigate(['/error'], {
                  queryParams: {
                    method: 'xLoginUrl', status: err.status,
                    correlationId: err.headers.get('x-correlation-id'), timestamp: this.windowRef.getTime()
                  }
                })
              }
            });
          } else {
            this.router.navigate(['/error'], {
              queryParams: {
                method: 'xValidateSession', status: error.status,
                correlationId: error.headers.get('x-correlation-id'), timestamp: this.windowRef.getTime()
              }
            })
          }
        }
      });
    });
  }

}
