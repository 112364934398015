import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

function _window(): Window {
    return window;
}

@Injectable()
export class WindowRef {

    get nativeWindow(): Window {
        return _window();
    }

    setLocationHref(url: string): void {
        this.nativeWindow.location.href = url;
    }

    getUserAgent(): string {
        return this.nativeWindow.navigator.userAgent;
    }

    getPathname(): string {
        return this.nativeWindow.location.pathname;
    }

    getSearch(): string {
        return this.nativeWindow.location.search;
    }

    getHostname(): string {
        return this.nativeWindow.location.hostname;
    }

    isOnline(): boolean {
        return this.nativeWindow.navigator.onLine;
    }

    getBrowserLanguage(): string {
        const language = this.nativeWindow.navigator.language;
        if (language) {
            return language.split('-')[0];
        }
        return environment.defaultLanguage;
    }

    shouldRedirect(pathname: string): boolean {
        let should = false;
        if (pathname === '/' || pathname === '' || pathname.indexOf('index.html') !== -1 ||
            pathname === '/loginwebapp/' || pathname === '/loginwebapp') {
            should = true;
        }
        return should;
    }

    getTime(){
        return new Date().getTime();
    }

}
