import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomainService } from 'src/app/core/domain/domain.service';
import { WindowRef } from 'src/app/core/domain/window-ref';
import { ProfileService } from 'src/app/core/translation/profile.service';

@Component({
  selector: 'app-system-unavailable-error',
  templateUrl: './system-unavailable-error.component.html'
})
export class SystemUnavailableErrorComponent implements OnInit {

  private translationIndex = 'systemUnavailableError';
  private commonIndex = 'common';
  message: any;
  correlationId: string;
  date: string;
  homeUrl: string;

  constructor(private pfs: ProfileService, private route: ActivatedRoute, private windowRef: WindowRef, private domain: DomainService) { }

  ngOnInit(): void {
    this.message = Object.assign(this.pfs.messages[this.commonIndex], this.pfs.messages[this.translationIndex]);
    this.homeUrl = this.domain.getHomeUrl(this.windowRef.getHostname());
    this.route.queryParams.subscribe(params => {
      this.correlationId = params['correlationId'];
      const timestamp = params['timestamp'];
      if (timestamp) {
        this.date = new Date(parseInt(timestamp)).toUTCString();
      }
    });
  }

}
